// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';

const menuItemFieldsFragment = `
  fragment menuItemFields on MenuItem {
    label
    url
    metadata
  }
`;

const menu = `
  menu(
    name: $name,
    slug: $slug,
    context: $context,
    addCounters: $addCounters
  ) {
    name
    label
    items {
      ...menuItemFields
      items {
        ...menuItemFields
        items {
          ...menuItemFields
          items {
            ...menuItemFields
            items {
              ...menuItemFields
              items {
                ...menuItemFields
              }
            }
          }
        }
      }
    }
  }
`;

const MENUS: DocumentNode = gql`
  ${menuItemFieldsFragment}

  query menu(
    $mainMenuName: String!,
    $mainMenuSlug: String,
    $mainMenuContext: String,
    $secondaryMenuName: String!,
    $secondaryMenuSlug: String,
    $secondaryMenuContext: String,
    $addCounters: Boolean
  ) {
    mainMenu: ${menu
    .replaceAll('$name', '$mainMenuName')
    .replaceAll('$slug', '$mainMenuSlug')
    .replaceAll('$context', '$mainMenuContext')
}
    secondaryMenu: ${menu
    .replaceAll('$name', '$secondaryMenuName')
    .replaceAll('$slug', '$secondaryMenuSlug')
    .replaceAll('$context', '$secondaryMenuContext')
}
  }
`;

const MENU: DocumentNode = gql`
  ${menuItemFieldsFragment}

  query Menu(
    $name: String!,
    $slug: String,
    $context: String,
    $addCounters: Boolean
  ) {
    ${menu}
  }
`;

export { MENUS, MENU };
